/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Divider, Grid, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";

import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import { CustomTextField } from "./formFields/CustomTextField";

const FieldModal = ({
  children,
  label,
  options,
  setFieldValue,
  value,
  field,
  setFieldTouched,
  touched,
  errors,
  isDark = false,
  ...rest
}) => {
  const [open, setopen] = useState(false);
  const [_val, setVal] = useState("");

  useEffect(() => {
    if (value) setVal(options?.find((val) => val.code === value)?.name);
    else setVal("");
  }, [value]);

  return (
    <>
      <Grid {...rest} my={2}>
        <Box onClick={() => setopen(true)}>
          <CustomTextField
            label={label}
            isViewMode={true}
            value={_val}
            touched={touched}
            errors={errors}
            isDark={!isDark}
          />
        </Box>
      </Grid>
      <Modal
        open={open}
        onClose={() => {
          setopen(false);
          if (!value) setFieldTouched(field, true);
        }}
        sx={{
          display: "grid",
          placeItems: "center",
        }}
      >
        <Box width={"90%"} sx={{ background: "#fff", borderRadius: 5 }}>
          {options?.map((type, index) => {
            return (
              <React.Fragment key={index}>
                <Box
                  sx={{
                    padding: "15px 15px",
                    borderRadius: "5px",
                    opacity: 0.8,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    cursor: "pointer",
                    ":hover": {
                      opacity: 1,
                    },
                  }}
                  onClick={() => {
                    setFieldValue(field, type.code);
                    setopen(false);
                  }}
                >
                  {type.name}
                  {value === type.code ? (
                    <RadioButtonCheckedIcon sx={{ color: "green" }} />
                  ) : (
                    <RadioButtonUncheckedIcon />
                  )}
                </Box>
                <Divider />
              </React.Fragment>
            );
          })}
        </Box>
      </Modal>
    </>
  );
};

export default FieldModal;
