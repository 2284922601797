import axios from "axios";
import store from "../redux/store";
import { notifyUser } from "../redux/slice";
import { setIsLoading } from "../redux/slice";

export const api = axios.create({
  baseURL: process.env.REACT_APP_PRO_MODE,
});

api.interceptors.request.use(
  (config) => {
    store.dispatch(setIsLoading(true));
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    store.dispatch(setIsLoading(false));
    return response;
  },
  async (error) => {
    store.dispatch(
      notifyUser([error?.response?.data?.error || error?.message, "error"])
    );
    store.dispatch(setIsLoading(false));
    return Promise.reject(error);
  }
);
