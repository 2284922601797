import BlueZero from "../assets/svg/B-zero.svg";
import BlueFirstPart from "../assets/svg/B-988.svg";
import BlueSecondPart from "../assets/svg/B-668.svg";
import fullBlueMail from "../assets/svg/blueMail.svg";
import { callTo, mailTo, showLocation } from "../utils/common";
import { FaceBookLogo, InstagramLogo, YoutubeLogo } from "../styles/contact";
import {
  PrimaryLocationIcon,
  PrimaryMailIcon,
  PrimaryPhoneIcon,
} from "../styles/contactUs";

export const FIRST_PART = "contact";
export const SECOND_PART = "@softtechashram.com";

export const ENCODED_NUM = "sttstteddt";

export const PRIVATE_KEY = 5;

export const ENCODE_FORMAT = {
  a: 1,
  y: 2,
  c: 3,
  z: 4,
  e: 5,
  l: 6,
  g: 7,
  n: 8,
  i: 9,
  q: 0,
  b: 10,
  d: 11,
  v: 12,
  t: 13,
  s: 14,
};

export const contact = {
  DETAILS: [
    {
      icon: PrimaryLocationIcon,
      value:
        "15, Gopalakrishnan Street, Secretariat Colony, Adambakkam, Chennai\xa0-\xa0600032",
      isMobileShow: false,
      isGap: "false",
      img: [],
      blueImg: [],
      onclick: showLocation,
    },
    {
      icon: PrimaryPhoneIcon,
      value: "994064728",
      isMobileShow: true,
      img: [BlueFirstPart, BlueFirstPart, BlueZero, BlueSecondPart],
      blueImg: [BlueFirstPart, BlueFirstPart, BlueZero, BlueSecondPart],
      isGap: "true",
      onclick: () => callTo(ENCODED_NUM, PRIVATE_KEY, ENCODE_FORMAT),
    },
    {
      icon: PrimaryMailIcon,
      value: "contact@softtechashram.com",
      isMobileShow: true,
      isGap: "false",
      img: [fullBlueMail],
      blueImg: [fullBlueMail],
      onclick: () => mailTo(FIRST_PART, SECOND_PART),
    },
  ],

  SOCIAL: [
    {
      Icon: FaceBookLogo,
      link: "https://www.facebook.com/softtechashram",
    },
    {
      Icon: YoutubeLogo,
      link: "https://www.youtube.com/@SoftTechAshram-mb1dv",
    },
    {
      Icon: InstagramLogo,
      link: "https://www.instagram.com/softtechashram3",
    },
  ],

  LOCATION:
    "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d432.7417384455793!2d80.2093946192229!3d12.996730874256457!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a5267add12a21cf%3A0x33a6279b991060bb!2sSoft%20Tech%20Ashram!5e0!3m2!1sen!2sin!4v1711087899391!5m2!1sen!2sin",
};

export const initialValues = {
  name: "",
  message: "",
  emailId: "",
  mobileNo: "",
  courseId: "",
  qualificationType: "engineering",
  qualificationId: "",
  yearOfPassing: "",
  cgpaOrPercentage: "",
};
