import React from "react";
import { Modal } from "@mui/material";

import { introVideoLink } from "../../constants/landing";
import {
  Iframe,
  CloseBtn,
  VideoBtn,
  VideoPaper,
  ModalContainer,
} from "../../styles/videoPlayer";

const VideoPlayer = ({ link, isOpen = false, handleClose = () => {} }) => {
  return (
    <Modal open={isOpen} onClose={handleClose}>
      <ModalContainer>
        <VideoBtn variant='contained' onClick={handleClose}>
          <CloseBtn />
        </VideoBtn>
        <VideoPaper elevation={5}>
          <Iframe
            src={link || introVideoLink}
            title='YouTube video player'
            frameBorder='0'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; web-share'
            allowFullScreen
          ></Iframe>
        </VideoPaper>
      </ModalContainer>
    </Modal>
  );
};

export default VideoPlayer;
