import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
} from "@mui/material";
import { styled } from "@mui/system";
import { theme } from "../../../styles/theme";

const RadioBox = styled("div")(({ rowbreak }) => ({
  display: "flex",
  alignItems: rowbreak ? "left" : "center",
  flexDirection: rowbreak && "column",
}));

export const CustomRadioButton = ({
  inputValues,
  name,
  onChange,
  onBlur,
  value = "",
  labelStyle,
  label,
  style,
  rowBreak,
  disabled,
  defaultValue,
  isViewMode,
  accessor,
  touched,
  errors,
  customHelperText,
  isDark = true,
}) => {
  return (
    <FormControl style={style}>
      <RadioBox
        rowbreak={rowBreak}
        style={{ justifyContent: "space-between", gap: 4 }}
      >
        <span
          style={{
            marginRight: "20px",
            fontSize: "18px",
            fontWeight: 500,
            color: isDark ? "#FFF" : theme?.palette?.primary?.main,
            ...labelStyle,
          }}
        >
          {label}
        </span>
        <RadioGroup
          aria-label='demo-radio-buttons-group-label'
          name={name}
          onChange={!isViewMode ? onChange : () => {}}
          onBlur={onBlur}
          error={Boolean(customHelperText || (touched && errors)).toString()}
          value={value || ""}
          defaultValue={defaultValue}
          row
        >
          {inputValues?.map((option, key) => {
            return (
              <FormControlLabel
                value={option[accessor]}
                control={
                  <Radio
                    onChange={(e) => e.target.blur()}
                    style={{
                      color: isDark ? "#FFF" : theme?.palette?.primary?.main,
                    }}
                  />
                }
                label={option?.name || option?.label}
                key={key}
                name={name}
                disabled={disabled}
                style={{
                  color: isDark ? "#FFF" : theme?.palette?.primary?.main,
                }}
              />
            );
          })}
        </RadioGroup>
      </RadioBox>
      <FormHelperText error>
        {customHelperText || (touched && errors) || " "}
      </FormHelperText>
    </FormControl>
  );
};
