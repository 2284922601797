import { Button } from "@mui/material";

export const initialValues = {
  sourceId: "",
  courseId: "",
  name: "",
  emailId: "",
  mobileNo: "",
  qualificationId: "",
  isHavingBasicProgrammingKnowlege: "",
  skills: "",
  qualificationType: "engineering",
  howDoYouKnowUs: "",
  yearOfPassing: "",
  knownFromOthers: "",
  total: "",
};

export const VHI_TECH_LAB_URL = "http://www.virdhitechlab.in/";

export const steps = [
  {
    stepColor: "#FEA815",
    titleColor: "#FFE6C3",
    title: "Watch below video to know our training",
  },
  {
    stepColor: "#8C497F",
    titleColor: "#E9CAD8",
    title: "Fill  the form and submit it",
  },
  // {
  //   stepColor: "#04C0B3",
  //   titleColor: "#ADF0E4",
  //   title: "Please wait for our student counselor's call",
  // },
];

export const promises = (handleClick = () => {}, isMobile) => [
  {
    stepColor: "#008000",
    titleColor: "#DAF7A6",
    title: (
      <>
        <span style={{ fontWeight: "800" }}>Talent Search</span> Training
        Program Sponsored by{" "}
        <Button
          sx={{
            textTransform: "initial",
            fontSize: isMobile ? "14px" : "18px",
            padding: "0 2px",
            pointerEvents: "none",
          }}
        >
          Virdhi Tech Lab Pvt Ltd
        </Button>
      </>
    ),
  },
  {
    stepColor: "#8C497F",
    titleColor: "#E9CAD8",
    title: <>Managed by Senior Software Professionals.</>,
  },
];
