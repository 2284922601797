export const apiPath = {
  SOURCE: "/seeds/source",
  CONTACT_US: "/contactUs",
  COURSE_LIST: "/courseList",
  EXPRESS_INTEREST: "/expressInterest",
  QUALIFICATION: "/seeds/qualification?qualificationType=",
  YEAR_OF_PASSING: "/seeds/yearOfPassing?isActive=1",
  STATE: "/seeds/state",
  DISTRICT: "/districts",
  QUALIFICATION_TYPE: "/seeds/qualificationType",
  KNOWN_SOURCE: "/seeds/knownSource",
  REQUEST_TYPE: "/seeds/requestType",
  NOI_DOVE_CONTACT:
    "https://api.neodove.com/integration/custom/744f4dc2-6710-47fa-88a2-20c5b2de76c9/leads",
  NOI_DOVE_INTERN:
    "https://api.neodove.com/integration/custom/aed09ed1-3dc7-456d-a9ac-d0c2aa5fe32f/leads",
  HOOK: "https://hook.us1.make.com/ha755uwpo9pammf7nqlf7eb59lecgby2",
};