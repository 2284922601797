import { Grid, Typography, styled } from "@mui/material";

import { WrapperContainer } from "./common";
import { SubHeader } from "./globalStyles";

export const GreetWrapper = styled(WrapperContainer)(({ theme }) => ({
  width: "100%",
  marginBottom: "30px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  [theme.breakpoints.up("sm")]: {
    width: "70%",
  },
}));

export const StepGrid = styled(Grid)(({ theme, bgcolor }) => ({
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: bgcolor,
  // borderRadius: "10px 0px 0px 10px",

  // [theme.breakpoints.up("sm")]: {
  borderRadius: "15px 0px 0px 15px",
  // [theme.breakpoints.up("md")]: {
  //   borderRadius: "20px 0px 0px 20px",
  // },
}));

export const StepText = styled(Grid)(({ theme }) => ({
  fontSize: "16px",
  color: "white",
  fontFamily: "Arial, Helvetica, sans-serif",
}));

export const Header = styled(SubHeader)(({ theme }) => ({
  [theme.breakpoints.down("sm")]: {
    fontSize: "18px",
  },
}));

export const StepTitleContainer = styled(Grid)(({ theme, bgcolor }) => ({
  alignItems: "center",
  backgroundColor: bgcolor,
  // borderRadius: "0px 10px 10px 0px",

  // [theme.breakpoints.up("sm")]: {
  borderRadius: "0px 15px 15px 0px",
  // },
  // [theme.breakpoints.up("md")]: {
  //   borderRadius: "0px 20px 20px 0px",
  // },
}));

export const TitleText = styled(Typography)(({ theme }) => ({
  fontSize: "16px",
  color: theme.palette.primary.main,
  marginLeft: "10px",
  [theme.breakpoints.up("sm")]: {
    fontSize: "20px",
    marginLeft: "20px",
  },
}));
