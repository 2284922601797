import { Button, Paper, styled } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const Header = styled("h2")(({ theme }) => ({
  fontWeight: 500,
  marginBottom: 9,
  fontSize: "clamp(25px, 2vw, 35px)",
  color: theme.palette.primary.main,
  [theme.breakpoints.down(600)]: { fontSize: 16 },
}));

export const ModalContainer = styled("div")(({ theme }) => ({
  top: "50%",
  left: "50%",
  width: "90%",
  border: "none",
  maxWidth: 800,
  margin: "0 auto",
  position: "absolute",
  transform: "translate(-50%, -50%)",

  [theme.breakpoints.down("sm")]: {
    minHeight: 220,
    maxHeight: 220,
  },
}));

export const Iframe = styled("iframe")(({ theme }) => ({
  width: "100%",
  height: "450px",
  [theme.breakpoints.down("sm")]: { height: "220px" },
}));

export const VideoBtn = styled(Button)(({ theme }) => ({
  position: "absolute",
  right: 0,
  top: 0,
  width: "30px",
  height: "30px",
  minWidth: 0,
  backgroundColor: "#E0E0E0 ",
  color: "black",
  ":hover": {
    backgroundColor: "red",
    color: "white",
  },
}));

export const CloseBtn = styled(CloseIcon)(({ theme }) => ({
  fontSize: "22px",
  color: "inherit",
}));

export const VideoPaper = styled(Paper)({
  padding: "5px 5px 0px 5px",
});
