export const welcome = "Welcome to Soft Tech Ashram";

export const slogan = `Gateway 2 Software Industry`;

export const introVideoLink =
  "https://www.youtube-nocookie.com/embed/UYIxSChq8Ew?autoplay=1&vq=hd1080&rel=0";

export const artsVideo =
  "https://www.youtube-nocookie.com/embed/IavEeb--VSg?autoplay=1&vq=hd1080&rel=0";

export const learnCycleLink =
  "https://www.youtube-nocookie.com/embed/utGXb0N7aOU?autoplay=1&vq=hd1080&rel=0";
