/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Container,
  Grid,
  IconButton,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setClosePromise, setContentLoaded } from "../../redux/slice";
import { routePath } from "../../routes/routePaths";
import { SubHeaderCenter } from "../../styles/common";
import { theme } from "../../styles/theme";
// import { PrimaryButton, btnHoverShadow } from "../../styles/globalStyles";
import Fireworks from "@fireworks-js/react";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import RefreshIcon from "@mui/icons-material/Refresh";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import { motion } from "framer-motion";
import { apiPath } from "../../api/apiPath";
import { getApiServices, postApiServices } from "../../api/apiServices";
import { initialValues, promises } from "../../constants/internship";
import { ContactGrid } from "../../styles/contactUs";
import {
  ClearButton,
  CustomDivider,
  FormContainer,
  PrimaryButton,
} from "../../styles/globalStyles";
import {
  StepGrid,
  StepText,
  StepTitleContainer,
  TitleText,
} from "../../styles/greetings";
import "../../styles/internship.css";
import useActions from "../../utils/useActions";
import useSeeds from "../../utils/useSeeds";
import { validationSchema } from "../../validations/internship";
import FieldModal from "../shared/FieldModal";
import { CustomRadioButton } from "../shared/formFields/CusomeRadioField";
import CustomAutoComplete from "../shared/formFields/CustomAutoComplete";
import { CustomTextField } from "../shared/formFields/CustomTextField";

const InternShip = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const ref = useRef();
  const [isFireWork, setFireWork] = useState(1);

  const { notifySuccess } = useActions();
  const [isCycleOpen, setCycleOpen] = useState(false);
  const [district, setDistrict] = useState([]);
  const [qualificationTypes, setQualificationType] = useState([]);
  const { courses, promiseContent } = useSelector((state) => state);
  const [digit, setDigit] = useState({
    firstDigit: parseInt(Math.random() * 30) || 1,
    secondDigit: parseInt(Math.random() * 4) || 1,
  });
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    dispatch(setClosePromise());
  }, []);

  const { source, qualificationType, knownSource, requestTypes } = useSeeds([
    { name: "source", path: apiPath.SOURCE },
    { name: "qualificationType", path: apiPath.QUALIFICATION_TYPE },
    { name: "knownSource", path: apiPath.KNOWN_SOURCE },
    { name: "requestTypes", path: apiPath.REQUEST_TYPE },
  ]);

  const { mutate: getDistrict } = useMutation({
    mutationKey: ["district"],
    mutationFn: (id) => getApiServices(`${apiPath.DISTRICT}/${id}`),
    onSuccess: (data) => {
      setDistrict(data?.data);
    },
  });

  const { mutate: getCourse } = useMutation({
    mutationKey: ["Course"],
    mutationFn: (id) => getApiServices(apiPath.QUALIFICATION + id),
    onSuccess: (data) => {
      setQualificationType(data?.data);
    },
  });

  useEffect(() => {
    dispatch(setContentLoaded(true));
  }, [dispatch]);

  const onSuccess = ({ data }) => {
    notifySuccess(data?.message);
    handleReset();
    navigate(routePath.THANK_YOU_INTERN, { state: { isIntern: true } });
  };

  const handleSubmitData = (value) => {
    const requestTypeId = requestTypes.find(
      (item) => item?.code === "intern-request"
    )?.id;

    const sourceId = source.find((item) => item?.code === "web-intern")?.id;

    const howDoYouKnowUs = (knownSource || []).find(
      (source) => source.code === value.howDoYouKnowUs
    )?.id;

    if (value.isHavingBasicProgrammingKnowlege === "0") {
      value.skills = "";
    }

    const data = Object.keys(value).reduce((result, objKey) => {
      const fieldValue = String(value[objKey]).replace(/\s+/g, " ").trim();
      if (fieldValue) result[objKey] = fieldValue;
      return result;
    }, {});

    postApiServices(apiPath.NOI_DOVE_INTERN, {
      name: data?.name,
      mobile: parseInt(data?.mobileNo),
      email: data?.emailId,
      yearOfPassing: parseInt(data?.yearOfPassing),
      qualification: data?.qualificationId,
      qualificationType: value?.qualificationType,
      havingBasicProgrammingKnowlege: parseInt(
        value?.isHavingBasicProgrammingKnowlege
      )
        ? "Yes"
        : "No",
      howDoyouKnowUs: value?.howDoYouKnowUs,
      ...(value?.howDoYouKnowUs === "others"
        ? { howDoyouKnowUs: data?.knownFromOthers }
        : {}),
      ...(parseInt(value?.isHavingBasicProgrammingKnowlege)
        ? { skills: data?.skills }
        : {}),
    });

    data.qualificationId = qualificationTypes?.find(
      (type) => type?.code === data?.qualificationId
    )?.id;

    const { qualificationType: c, ...rest } = data;



     postApiServices(apiPath?.HOOK, {
       ...rest,
       isHavingBasicProgrammingKnowlege: parseInt(rest.isHavingBasicProgrammingKnowlege),
       yearOfPassing: parseInt(rest.yearOfPassing),
       howDoYouKnowUs,
       requestTypeId,
       sourceId,
     });
    

    return postApiServices(apiPath.EXPRESS_INTEREST, {
      ...rest,
      isHavingBasicProgrammingKnowlege: parseInt(
        rest.isHavingBasicProgrammingKnowlege
      ),
      yearOfPassing: parseInt(rest.yearOfPassing),
      howDoYouKnowUs,
      requestTypeId,
      sourceId,
    });
  };

  const { mutate: onSubmit } = useMutation({
    mutationKey: ["Contact with us"],
    mutationFn: handleSubmitData,
    onSuccess,
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    handleReset,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (value) => {
      if (value?.total !== digit?.firstDigit + digit?.secondDigit) {
        setFieldError("total", "Please enter correct Value");
        setDigit({
          firstDigit: parseInt(Math.random() * 30) || 1,
          secondDigit: parseInt(Math.random() * 4) || 1,
        });
        return;
      }
      onSubmit(value);
    },
  });
  const fetchCourse = () => {
    if (qualificationType) {
      const id = qualificationType?.find(
        (type) => type?.code === values.qualificationType
      )?.id;
      getCourse(id);
    }
  };

  useEffect(() => {
    setFieldValue("qualificationId", "");
    fetchCourse();
  }, [values.qualificationType]);

  useEffect(() => {
    setFieldValue("knownFromOthers", "");
  }, [values.howDoYouKnowUs]);

  useEffect(() => {
    if (values.isHavingBasicProgrammingKnowlege === "0") {
      setFieldValue("skills", "");
      setFieldTouched("skills", false);
    }
  }, [values.isHavingBasicProgrammingKnowlege]);

  useEffect(() => {
    fetchCourse();
  }, [qualificationType]);

  useEffect(() => {
    dispatch(setContentLoaded(true));
  }, [dispatch]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setFireWork(0);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Helmet>
        <title>SoftTech Ashram | Free Internship</title>
        <meta
          name='description'
          content="At Soft Tech Ashram, Chennai's leading software training hub, we offer comprehensive courses in programming, web development."
        />
        <meta
          name='keywords'
          content='Software Training Institute, Chennai Software Courses, Programming Courses, Web Development Training, Technology Education Chennai, Coding Bootcamp Chennai,Software Engineering Courses,IT Courses Chennai'
        />
        <meta name='twitter:card' content='Software Training Institute' />
        <meta name='twitter:title' content='Soft Tech Ashram' />
        <meta
          name='twitter:description'
          content="At Soft Tech Ashram, Chennai's leading software training hub, we offer comprehensive courses in programming, web development."
        />
        <meta
          name='twitter:image'
          content='https://softtechashram.com/static/media/logo.94770ad14aa55ae4c92f.png'
        />

        <meta property='og:title' content='Soft Tech Ashram' />
        <meta
          property='og:description'
          content="At Soft Tech Ashram, Chennai's leading software training hub, we offer comprehensive courses in programming, web development."
        />
        <meta
          property='og:image'
          content='https://softtechashram.com/static/media/logo.94770ad14aa55ae4c92f.png'
        />
        <meta property='og:url' content='https://softtechashram.com' />
        <meta property='og:type' content='Software Training Institute' />
      </Helmet>

      {!!isFireWork && (
        <Fireworks
          ref={ref}
          options={{ opacity: 0.5, acceleration: isFireWork }}
          style={{
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            position: "fixed",
          }}
        />
      )}

      <Box sx={{ height: "100%", background: "white" }}>
        <Container maxWidth='lg' sx={{ height: "100%" }}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ height: "100%", minHeight: "100vh" }}
          >
            <Grid container item xs={12} alignItems={"center"}>
              <Grid container item xs={12} rowGap={2} justifyContent={"center"}>
                <Grid container item xs={12} justifyContent={"center"}>
                  <Grid item xs={12}>
                    <motion.div
                      initial={{
                        opacity: 0,
                        paddingLeft: "60px",
                      }}
                      whileInView={{
                        opacity: 100,
                        paddingLeft: "0px",
                      }}
                      transition={{ delay: 0.2 }}
                    >
                      <SubHeaderCenter
                        sx={{
                          fontWeight: "800",
                          fontSize: "32px !important",
                          color: "#003366",
                        }}
                      >
                        Soft <span style={{ color: "#FD5800" }}>Tech</span>{" "}
                        Ashram
                      </SubHeaderCenter>
                    </motion.div>
                    <motion.div
                      initial={{
                        opacity: 0,
                        paddingRight: "60px",
                      }}
                      whileInView={{
                        opacity: 100,
                        paddingRight: "0px",
                      }}
                      transition={{ delay: 0.25 }}
                    >
                      <SubHeaderCenter
                        sx={{
                          fontWeight: "700",
                          fontSize: "22px",
                          color: "#003366",
                        }}
                      >
                        100% Free Software Training{" "}
                        <span style={{ color: "#ff7b25" }}>(தமிழில்)</span>
                      </SubHeaderCenter>
                      <SubHeaderCenter
                        sx={{
                          fontWeight: "700",
                          fontSize: "22px",
                          color: "#003366",
                        }}
                      >
                        Job Oriented, 4-5 weeks, Full time with certification @
                        Velachery, CHENNAI – Offline Only
                      </SubHeaderCenter>
                      <SubHeaderCenter
                        sx={{
                          fontWeight: "800",
                          fontSize: "24px !important",
                          color: "#FD5800",
                        }}
                      >
                        (முழுவதும் தமிழில் கற்போம்)
                      </SubHeaderCenter>
                    </motion.div>
                  </Grid>
                  {/* <Grid container item xs={12} justifyContent={"center"}>
                    <Grid item xs={5} sm={3} md={1}>
                      <Box sx={{ width: "100%" }}>
                        <motion.div
                          initial={{ scale: 0, rotate: 90 }}
                          animate={{ rotate: 0, scale: 1 }}
                          transition={{
                            delay: 1,
                          }}
                        >
                          <ImgFluid src={logo} alt='Soft Tech Ashram logo' />
                        </motion.div>
                      </Box>
                    </Grid>
                  </Grid> */}
                </Grid>

                <Grid container rowGap={2} item xs={12} md={7}>
                  {promises(() => navigate(routePath.ABOUT_US), isMobile)?.map(
                    (promise, index) => (
                      <Grid container key={index}>
                        <StepGrid
                          container
                          item
                          xs={2.5}
                          sm
                          md={2.5}
                          bgcolor={promise?.stepColor || ""}
                        >
                          <Grid item>
                            <StepText>
                              <DoneOutlineIcon />
                            </StepText>
                          </Grid>
                        </StepGrid>
                        <StepTitleContainer
                          container
                          item
                          xs
                          sm={10}
                          md
                          bgcolor={promise?.titleColor || ""}
                          sx={{
                            padding: "5px",
                            minHeight: isMobile ? "75px" : "70px",
                            maxHeight: isMobile ? "75px" : "70px",
                          }}
                        >
                          <Grid item>
                            <TitleText
                              sx={{ fontSize: isMobile ? "14px" : "12px" }}
                            >
                              {promise?.title}
                            </TitleText>
                          </Grid>
                        </StepTitleContainer>
                      </Grid>
                    )
                  )}
                </Grid>
                <Grid container item xs={12} md={7} m='auto' rowGap={2}>
                  <Grid
                    item
                    xs={12}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      width: "100%",
                      justifyContent: "center",
                      flexWrap: "wrap",
                      rowGap: "10px",
                    }}
                  ></Grid>
                  <Grid item xs={12}>
                    <SubHeaderCenter
                      style={{ color: theme?.palette?.primary?.main }}
                    >
                      Enrolment Form
                    </SubHeaderCenter>
                  </Grid>
                </Grid>

                <Grid container rowGap={3} my={2}>
                  <Grid
                    item
                    xs={12}
                    container
                    rowGap={4.8}
                    id='contactUs'
                    columnSpacing={"25px"}
                    justifyContent={"center"}
                  >
                    <ContactGrid container item xs={12} md={7}>
                      <Grid item xs={12}>
                        <FormContainer onSubmit={handleSubmit}>
                          <Grid container rowSpacing={2} columnSpacing={"25px"}>
                            <>
                              <Grid item xs={12} sm={6}>
                                <CustomTextField
                                  name={"name"}
                                  label={"Name *"}
                                  onBlur={handleBlur}
                                  value={values?.name}
                                  onChange={handleChange}
                                  errors={errors?.name}
                                  touched={touched?.name}
                                  isDark={false}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <CustomTextField
                                  name={"emailId"}
                                  label={"Email *"}
                                  onBlur={handleBlur}
                                  value={values?.emailId}
                                  onChange={handleChange}
                                  errors={errors?.emailId}
                                  touched={touched?.emailId}
                                  isDark={false}
                                />
                              </Grid>
                              <Grid item xs={12} sm={6}>
                                <CustomTextField
                                  name={"mobileNo"}
                                  label={"Phone *"}
                                  onBlur={handleBlur}
                                  value={values?.mobileNo}
                                  onChange={handleChange}
                                  errors={errors?.mobileNo}
                                  touched={touched?.mobileNo}
                                  maxLength={10}
                                  type={"number"}
                                  isDark={false}
                                />
                              </Grid>

                              <Grid item xs={12}>
                                <CustomDivider sx={{ marginTop: 0 }} />
                              </Grid>
                            </>

                            <>
                              <Grid item xs={12}>
                                <CustomRadioButton
                                  name='qualificationType'
                                  rowBreak={isMobile}
                                  inputValues={qualificationType || []}
                                  onChange={handleChange}
                                  value={values?.qualificationType}
                                  accessor={"code"}
                                  isDark={false}
                                />
                              </Grid>
                              {isMobile ? (
                                <FieldModal
                                  item
                                  xs={12}
                                  sm={6}
                                  label='Qualification *'
                                  options={qualificationTypes}
                                  setFieldValue={setFieldValue}
                                  value={values?.qualificationId}
                                  field='qualificationId'
                                  setFieldTouched={setFieldTouched}
                                  errors={errors?.qualificationId}
                                  touched={touched?.qualificationId}
                                  isDark
                                />
                              ) : (
                                <Grid item xs={12} sm={6}>
                                  <Box
                                    sx={{
                                      background: "#fff",
                                      cursor: "pointer",
                                    }}
                                  >
                                    <CustomAutoComplete
                                      name='qualificationId'
                                      label='Qualification *'
                                      // onChange={setFieldValue}
                                      customOnchange={(e, value) => {
                                        setFieldValue(
                                          "qualificationId",
                                          value?.id
                                        );
                                      }}
                                      value={values?.qualificationId}
                                      inputValues={qualificationTypes || []}
                                      errors={errors?.qualificationId}
                                      touched={touched?.qualificationId}
                                      onBlur={handleBlur}
                                      fullWidth
                                      size={isMobile ? "small" : ""}
                                      isDark={false}
                                    />
                                  </Box>
                                </Grid>
                              )}

                              <Grid item xs={12} sm={6}>
                                <CustomTextField
                                  name='yearOfPassing'
                                  label='Year of passing *'
                                  onChange={handleChange}
                                  value={values?.yearOfPassing}
                                  errors={errors?.yearOfPassing}
                                  touched={touched?.yearOfPassing}
                                  placeholder={"YYYY"}
                                  onBlur={handleBlur}
                                  maxLength={4}
                                  minLength={2}
                                  type={"number"}
                                  fullWidth
                                  size={isMobile ? "small" : ""}
                                  isDark={false}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <CustomDivider sx={{ marginTop: 0 }} />
                              </Grid>
                            </>

                            <>
                              {isMobile ? (
                                <FieldModal
                                  item
                                  xs={12}
                                  sm={6}
                                  label='Do You have basic Programming Knowledge?'
                                  options={[
                                    {
                                      name: "Yes",
                                      code: "1",
                                    },
                                    {
                                      name: "No",
                                      code: "0",
                                    },
                                  ]}
                                  setFieldValue={setFieldValue}
                                  value={
                                    values?.isHavingBasicProgrammingKnowlege
                                  }
                                  errors={
                                    errors?.isHavingBasicProgrammingKnowlege
                                  }
                                  touched={
                                    touched?.isHavingBasicProgrammingKnowlege
                                  }
                                  field='isHavingBasicProgrammingKnowlege'
                                  setFieldTouched={setFieldTouched}
                                  isDark
                                />
                              ) : (
                                <Grid item xs={12}>
                                  <CustomRadioButton
                                    label={
                                      "Do You have basic Programming Knowledge?"
                                    }
                                    name='isHavingBasicProgrammingKnowlege'
                                    rowBreak={isMobile}
                                    errors={
                                      errors?.isHavingBasicProgrammingKnowlege
                                    }
                                    touched={
                                      touched?.isHavingBasicProgrammingKnowlege
                                    }
                                    inputValues={[
                                      {
                                        label: "Yes",
                                        value: "1",
                                      },
                                      {
                                        label: "No",
                                        value: "0",
                                      },
                                    ]}
                                    onChange={handleChange}
                                    value={
                                      values?.isHavingBasicProgrammingKnowlege
                                    }
                                    accessor={"value"}
                                    isDark={false}
                                  />
                                </Grid>
                              )}
                              {values.isHavingBasicProgrammingKnowlege ===
                                "1" && (
                                <Grid item xs={12}>
                                  <CustomTextField
                                    name={"skills"}
                                    label={"Progamming Skills *"}
                                    onBlur={handleBlur}
                                    value={values?.skills}
                                    onChange={handleChange}
                                    errors={errors?.skills}
                                    touched={touched?.skills}
                                    isDark={false}
                                  />
                                </Grid>
                              )}
                            </>
                            {isMobile ? (
                              <FieldModal
                                item
                                xs={12}
                                sm={6}
                                label='How Do You Know Us?'
                                options={knownSource}
                                setFieldValue={setFieldValue}
                                value={values?.howDoYouKnowUs}
                                field='howDoYouKnowUs'
                                setFieldTouched={setFieldTouched}
                                errors={errors?.howDoYouKnowUs}
                                touched={touched?.howDoYouKnowUs}
                                isDark
                              />
                            ) : (
                              <Grid item xs={12}>
                                <CustomRadioButton
                                  label={"How Do You Know Us ? *"}
                                  errors={errors?.howDoYouKnowUs}
                                  touched={touched?.howDoYouKnowUs}
                                  name='howDoYouKnowUs'
                                  rowBreak={true}
                                  inputValues={knownSource || []}
                                  onChange={handleChange}
                                  value={values?.howDoYouKnowUs}
                                  accessor={"code"}
                                  isDark={false}
                                />
                              </Grid>
                            )}
                            {values.howDoYouKnowUs === "others" && (
                              <Grid item xs={12}>
                                <CustomTextField
                                  name={"knownFromOthers"}
                                  label={"Source*"}
                                  onBlur={handleBlur}
                                  value={values?.knownFromOthers}
                                  onChange={handleChange}
                                  errors={errors?.knownFromOthers}
                                  touched={touched?.knownFromOthers}
                                  isDark={false}
                                />
                              </Grid>
                            )}
                            <Grid
                              item
                              xs={5}
                              sm={7}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "flex-end",
                              }}
                            >
                              <Typography
                                sx={{
                                  textAlign: "right",
                                  fontSize: "28px",
                                  color: theme?.palette?.primary?.main,
                                }}
                              >
                                {digit?.firstDigit} + {digit?.secondDigit} =
                              </Typography>
                            </Grid>
                            <Grid item xs={5} sm={3}>
                              <CustomTextField
                                name='total'
                                label='Total *'
                                onChange={handleChange}
                                value={values?.total}
                                errors={errors?.total}
                                touched={touched?.total}
                                onBlur={handleBlur}
                                maxLength={4}
                                type={"number"}
                                fullWidth
                                size={isMobile ? "small" : ""}
                                isDark={false}
                              />
                            </Grid>
                            <Grid item xs={1}>
                              <IconButton
                                onClick={() => {
                                  setDigit({
                                    firstDigit:
                                      parseInt(Math.random() * 30) || 1,
                                    secondDigit:
                                      parseInt(Math.random() * 4) || 1,
                                  });
                                }}
                              >
                                <RefreshIcon
                                  sx={{
                                    fontSize: "32px",
                                    color: theme?.palette?.primary?.main,
                                  }}
                                />
                              </IconButton>
                            </Grid>

                            <Grid
                              container
                              item
                              justifyContent={"flex-end"}
                              sx={{ marginTop: "24px", marginBottom: "28px" }}
                            >
                              <Grid item>
                                <ClearButton
                                  variant='contained'
                                  disableElevation
                                  onClick={handleReset}
                                >
                                  Clear
                                </ClearButton>
                                <PrimaryButton
                                  variant='contained'
                                  disableElevation
                                  type='submit'
                                >
                                  Submit
                                </PrimaryButton>
                              </Grid>
                            </Grid>
                          </Grid>
                        </FormContainer>
                      </Grid>
                    </ContactGrid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default InternShip;
